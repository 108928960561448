import React from "react";
import Wrap from "../components/wrap";
import { v4 } from "uuid";
import { graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import RawImage from "../images/example/photo6.jpg";

function ComponentsPage() {
  // Query name should be unique
  // Image prefix should be unique
  // Use relativePath { eq: "foo.jpg"} to query image you want
  // The curly brases accept props that help modify the image
  // Learn more image props here: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image

  const images = useStaticQuery(graphql`
    query ExampleImages {
      ExampleFluid: file(relativePath: { eq: "example/photo6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, rotate: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ExampleFluidMaxHeight: file(relativePath: { eq: "example/photo6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 200, rotate: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ExampleFixedSquare: file(relativePath: { eq: "example/photo6.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ExampleFixedRectangle: file(relativePath: { eq: "example/photo6.jpg" }) {
        childImageSharp {
          fixed(width: 400, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      imagesFromJSON: allExampleGalleryJson {
        edges {
          node {
            alt
            style
            src {
              id
              childImageSharp {
                fluid(maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Wrap className="">
        <section className="p-4 shadow-lg border rounded">
          <header className="mb-8">
            <h1 className="c-h3 mb-2">Images</h1>
            <p>
              We use gatsby-plugin-sharp to source images in graphql. The
              benefit of loading images through graphql is that Gatsby exposes
              image optimization features. As a bonus, it also possible to use
              additional image filters.
            </p>
          </header>
          <div className="my-8">
            <div className="mb-3">
              <h2 className="c-h4 mb-1">Raw</h2>
              <p>
                Here is an example of a raw image. If we do not source it, we
                will load 1.7mb to the client.
              </p>
            </div>
            <img src={RawImage} alt="Some Tag" className="mb-4 rounded-sm" />
          </div>
          <div className="my-8">
            <div className="mb-3">
              <h2 className="c-h3 mb-1">Fluid</h2>
              <p>A fluid image always stretches to full-width.</p>
            </div>
            <Img
              fluid={images.ExampleFluid.childImageSharp.fluid}
              className="radius mb-4"
              alt="Alt Tag"
            />
            <Img
              fluid={images.ExampleFluidMaxHeight.childImageSharp.fluid}
              className="radius"
              alt="Alt Tag"
            />
          </div>
          <div className="my-8">
            <div className="mb-3">
              <h2 className="c-h3 mb-1">Fixed</h2>
              <p>A fixed image has a set width and height.</p>
            </div>
            <Img
              fixed={images.ExampleFixedSquare.childImageSharp.fixed}
              className="radius mr-4"
              alt="Alt Tag"
            />
            <Img
              fixed={images.ExampleFixedRectangle.childImageSharp.fixed}
              className="radius"
              alt="Alt Tag"
            />
          </div>
          <div className="my-8">
            <div className="mb-3">
              <h2 className="c-h3 mb-1">Gallery</h2>
              <p>
                It's better to use JSON to load a collection of images.
                Attributes such as style, alt, src should be defined in JSON and
                passed to a component through a graphql query.
              </p>
            </div>
            <div className="flex">
              {images.imagesFromJSON.edges.map(item => {
                return (
                  <Img
                    key={v4()}
                    fluid={item.node.src.childImageSharp.fluid}
                    className={item.node.style}
                    alt={item.node.alt}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </Wrap>
    </>
  );
}

export default ComponentsPage;
